import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import "./App.css";
import Home from "./routes/Home";
import Login from "./routes/Login";
import Navbar from "./components/Navbar";

function App() {
	return (
		<Router>
			<Navbar />
			<Container>
				<Switch>
					<Route path="/login">
						<Login />
					</Route>
					<Route path="/">
						<Home />
					</Route>
				</Switch>
			</Container>
		</Router>
	);
}

export default App;
